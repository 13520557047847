<div class="card">
  <div class="card-header border-0 pt-6 ">
    <!--begin::Card title-->
    <div class="card-title">
      <!--begin::Search-->
      <div class="d-flex align-items-center position-relative my-1">
        <!--<app-keenicon name="magnifier" class="fs-3 position-absolute ms-5"></app-keenicon>-->
        <input type="text" data-action="filter" class="form-control form-control-solid w-250px ps-12" placeholder="Search Users">
      </div>
      <!--end::Search-->
    </div>
    <!--begin::Card title-->
    <!--begin::Card toolbar-->
    <div class="card-toolbar">
      <!--begin::Toolbar-->
      <div class="d-flex justify-content-end">
        <!--begin::Add permission-->
        <button type="button" class="btn btn-primary" data-action="create">
          Add Numbering Setup
        </button>
        <!--end::Add permission-->
      </div>
      <!--end::Toolbar-->
    </div>
    <!--end::Card toolbar-->
    <table class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
      <thead>
        <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
          <ng-container>
            <th>ID</th>
            <th>ID</th>
            <th>ID</th>
            <th>ID</th>
            <th>Actions</th>
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>123</td>
          <td>1234</td>
          <td>1235</td>
          <td>1236</td>
          <td>
            <button class="btn btn-icon btn-active-light-primary w-30px h-30px me-3" data-action="edit" data-id="${full.id}">
              <i class="ki-duotone ki-pencil fs-3"><span class="path1"></span><span class="path2"></span></i>
            </button>
            <button class="btn btn-icon btn-active-light-primary w-30px h-30px" data-action="delete" data-id="${full.id}">
              <i class="ki-duotone ki-trash fs-3">
                <span class="path1"></span><span class="path2"></span>
                <span class="path3"></span><span class="path4"></span><span class="path5"></span>
              </i>
            </button>
          </td>
        </tr>
      </tbody>
      <tbody class="fw-semibold text-gray-600">
      </tbody>
    </table>
  </div>

</div>
<swal #noticeSwal [swalOptions]="swalOptions">
</swal>
<swal #deleteSwal [swalOptions]="swalOptions" title="Are you sure to delete?" text="This cannot be undone" icon="warning" [showCancelButton]="true"
      [focusCancel]="true" (confirm)="triggerDelete()"
      [customClass]="{confirmButton: 'btn btn-danger', cancelButton: 'btn btn-active-light'}">
</swal>

<swal #successSwal [swalOptions]="swalOptions" title="Delete successfully!" text="" icon="success"
      [customClass]="{confirmButton: 'btn btn-success'}">
</swal>




<ng-template #formModal let-modal>
  <form  >
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Permission Details</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <div class="d-flex flex-column scroll-y me-n7 pe-7 mw-650px" id="kt_modal_update_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_update_customer_header" data-kt-scroll-wrappers="#kt_modal_update_customer_scroll"
           data-kt-scroll-offset="300px">
        <!--begin::User toggle-->
        <div class="fw-bold fs-3 rotate collapsible mb-7" data-bs-toggle="collapse" role="button" (click)="collapse1.toggle()" [attr.aria-expanded]="!isCollapsed1" aria-controls="kt_modal_update_customer_user_info">
          User Information
          <span class="ms-2 rotate-180">
            <i class="ki-duotone ki-down fs-3" [ngClass]="{ 'ki-up': !isCollapsed1 }"></i>
          </span>
        </div>
        <!--end::User toggle-->
        <!--begin::User form-->
        <div class="collapse show" #collapse1="ngbCollapse" [(ngbCollapse)]="isCollapsed1">
          <!--begin::Input group-->
          <div class="fv-row mb-7">
            <!--begin::Label-->
            <label class="required fw-semibold fs-6 mb-2">Name</label>
            <!--end::Label-->
            <!--begin::Input-->
            <input type="text" class="form-control form-control-solid" name="name" required minlength="4" />
            <!--end::Input-->
          </div>
          <!--end::Input group-->
        </div>
        <!--end::User form-->
      </div>
    </div>
    <div class="modal-footer flex-center">
      <!--begin::Button-->
      <button type="reset" id="kt_modal_update_customer_cancel" class="btn btn-light me-3" (click)="modal.dismiss('cancel')">
        Discard
      </button>
      <!--end::Button-->
      <!--begin::Button-->
      <button type="submit" id="kt_modal_update_customer_submit" class="btn btn-primary" [attr.data-kt-indicator]="isLoading ? 'on' : 'off'">
        <span class="indicator-label">
          Submit
        </span>
        <span class="indicator-progress">
          Please wait... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
      </button>
      <!--end::Button-->
    </div>
  </form>
</ng-template>
